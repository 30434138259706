<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>
    <CampaignsUpNext />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import CampaignsUpNext from "@/components/campaign_management/annual_marketing/CampaignsUpNext.vue";

export default {
  name: "AnnualMarketing",

  components: {
    PageHeader,
    CampaignsUpNext,
  },

  data() {
    return {
      pageTitle: "Jahresmarketing - Übersicht",
      pageDescription: "Chronologischer Ablaufplan, die nächsten 4 Wochen",
    };
  },
};
</script>
